import Image from "next/image";
import React, { ReactElement } from "react";

export default function Benefits(): ReactElement {
    return (
        <section className={`max-w-7xl mx-auto text-left py-10 px-4`}>
            <div className="flex flex-col lg:flex-row items-center gap-10 mb-20">
                <div className="relative min-w-[300px] h-[200px] lg:min-w-[525px] lg:h-[444px]">
                    <Image
                        src="/longrangemovers/assets/images/info_1.png"
                        alt="Benefits"
                        layout="fill"
                    />
                </div>
                <div>
                    <h2 className={` font-bold text-xl lg:text-3xl mb-8`}>
                        What Is a Long Distance Move?
                    </h2>
                    <p className={`text-base`}>
                        Long-distance moves, including cross-country,
                        interstate, or state-to-state relocations, differ from
                        local or intrastate moves as they require crossing state
                        lines. Such moves necessitate special federal
                        authorization to comply with U.S. laws and regulations,
                        including insurance requirements. These laws aim to
                        protect consumers throughout the moving process. It's
                        crucial to identify and choose reputable long-distance
                        moving companies is crucial to avoid fraudulent movers
                        known for scams such as holding belongings hostage for
                        extra fees. Always verify the credibility of movers to
                        ensure a safe and secure relocation.
                    </p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-10">
                <div>
                    <h2 className={` font-bold text-xl lg:text-3xl mb-8`}>
                        How Much Does a Long Distance Move Cost?
                    </h2>
                    <p className={`text-base`}>
                        The cost of moving services varies based on the volume
                        of items being moved, the distance of the move, and the
                        selected move date. Full-service moving companies
                        generally have higher rates, but prices can differ
                        significantly across providers. Some movers calculate
                        costs based on the size of the moving equipment needed,
                        while others price their services based on the weight or
                        the amount of space your belongings occupy.
                    </p>
                </div>
                <div className="relative w-[300px] h-[180px] lg:min-w-[504px] lg:h-[304px]">
                    <Image
                        src="/longrangemovers/assets/images/info_2.png"
                        alt="Benefits"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
}
